html {
    position: fixed;
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    width: 100vw;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #050505;
}

* {
    font-family: Colfax-Light, sans-serif;
}

.no-overflow {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.font-bold {
    font-family : Colfax-Bold !important;
    font-weight: 600 !important;
}

.font-medium {
    font-family : Colfax-Medium !important;
    font-weight: 600 !important;
}

.pl-3r {
    padding-left: 3vw;
}

h3 {
    font-size : 20px;
    text-decoration: underline;
}

@font-face {
    font-family: Colfax-Regular;
    src: url("/src/assets/fonts/Colfax-Regular.otf") format("opentype");
}

@font-face {
    font-family: Colfax-Medium;
    font-weight: 600;
    src: url("/src/assets/fonts/Colfax-Medium.otf") format("opentype");
}

@font-face {
    font-family: Colfax-Bold;
    font-weight: 600;
    src: url("/src/assets/fonts/Colfax-Bold.otf") format("opentype");
}

@font-face {
    font-family: Colfax-Thin;
    src: url("/src/assets/fonts/Colfax-Thin.otf") format("opentype");
}

@font-face {
    font-family: Colfax-light;
    src: url("/src/assets/fonts/Colfax-Light.otf") format("opentype");
}


/* HAMBURGER CSS */

#menuToggle {
    overflow: hidden;
    position: absolute;
    display: flex;
    right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#menuToggle input {
    display: flex;
    width: 45px;
    height: 45px;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* hide input */
    z-index: 1; /* top of the menu */
}

#menuToggle span {
    width: 41.25px;
    height: 5px;
    margin-bottom: 7.5px;
    background: #ffffff;
    border-radius: 2px;
    -webkit-transition: all .5s cubic-bezier(.08, .81, .87, .71);
    -moz-transition: all .5s cubic-bezier(.08, .81, .87, .71);
    -ms-transition: all .5s cubic-bezier(.08, .81, .87, .71);
    -o-transition: all .5s cubic-bezier(.08, .81, .87, .71);
    transition: all .5s cubic-bezier(.08, .81, .87, .71);
}

#span1 {
    transform-origin: 3px 0;
}

#span3 {
    transform-origin: bottom right;
}

#menuToggle input:checked ~ #span1 {
    background-color: #ffffff;
    transform: rotate(45deg) translate(6px);
}

#menuToggle input:checked ~ #span2 {
    background-color: #ffffff;
    transform: rotate(495deg) translate(3px);
}

#menuToggle input:checked ~ #span3 {
    background-color: #ffffff;
    transform: rotate(45deg);
    opacity: 0;
}

/* HAMBURGER CSS */

.shape {
    position: relative;
    width: 160%;
    height: 90%;
    transform-origin: left;
    overflow-x: hidden;
    transform: translate(-10%, 35%) rotate(-13deg);
    background: #cdff00;
}

@media (max-width: 992px) {
    .shape {
        transform: translate(-10%, 22%) rotate(-13deg);
        width: 200%;
        height: 80%;
    }
}

.image-slider {
    filter: invert(100%) sepia(0%) saturate(7449%) hue-rotate(89deg) brightness(97%) contrast(115%);
}

/* Change background image */
#bgHome {
    animation: changeBg 20s infinite
}

@keyframes changeBg {
    0%, 30% {
        background-image: url("/src/_images/backgrounds/bg-1.png");
    }
    35%, 60% {
        background-image: url("/src/_images/backgrounds/bg-2.png");
    }
    65%, 95% {
        background-image: url("/src/_images/backgrounds/bg-3.png");
    }
}

/* Slider */

.slider {
    animation: shake 15s linear 0s infinite normal forwards;
}

.slider:hover {
    animation-play-state: paused;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        transform: translateX(-100px);
    }

    20%,
    40%,
    60% {
        transform: translateX(100px);
    }

    80% {
        transform: translateX(100px);
    }

    90% {
        transform: translateX(-100px);
    }
}

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 5s ease-in 3s;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 5s;
}

.carousel-container ul {
    height: 100%;
}


@keyframes scroll-motors {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-255px * 5));
    }
}

.carousel-motors {
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.carousel-motors::before, .carousel-motors::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 210px;
    z-index: 2;
}

.carousel-motors::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.carousel-motors::before {
    left: 0;
    top: 0;
}

.carousel-motors .carousel-track {
    animation: scroll-motors 10s linear infinite;
    display: flex;
    width: calc(250px * 10);
}

.carousel-motors:hover .carousel-track {
    animation-play-state: paused;
}

.carousel-motors .image-carousel {
    height: 100px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* OEM Carousel */
@keyframes scroll-oem {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-253px * 10));
    }
}

.carousel-oem {
    /*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);*/
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.carousel-oem::before, .carousel-oem::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 210px;
    z-index: 2;
}

.carousel-oem::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.carousel-oem::before {
    left: 0;
    top: 0;
}

.carousel-oem .carousel-track-oem {
    animation: scroll-oem 30s linear infinite;
    display: flex;
    width: calc(250px * 20);
}

.carousel-oem:hover .carousel-track-oem {
    animation-play-state: paused;
}

.carousel-oem .image-carousel-oem {
    height: 100px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* FLEET Carousel */
@keyframes scroll-fleet {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-255px * 5));
    }
}

.carousel-fleet {
    /*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);*/
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.carousel-fleet::before, .carousel-fleet::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 210px;
    z-index: 2;
}

.carousel-fleet::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.carousel-fleet::before {
    left: 0;
    top: 0;
}

.carousel-fleet .carousel-track-fleet {
    animation: scroll-fleet 20s linear infinite;
    display: flex;
    width: calc(250px * 10);
}

.carousel-fleet:hover .carousel-track-fleet {
    animation-play-state: paused;
}

.carousel-fleet .image-carousel-fleet {
    height: 100px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-404 {
    background-image: url("../assets/img/404.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.menu-enter {
    transition: opacity 0.5s;
    opacity: 1;
}
.menu-enter.hide {
    opacity: 0;
    pointer-events:none;
}
